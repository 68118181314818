
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'bank-rates-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('bankRatesListing'), [
          translate('bank'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_BANK_RATES_STORE);
      });

      await store.dispatch(Actions.GET_BANK_RATES);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('boubyanCustomer'),
          key: 'percentage',
          sortable: true,
        },
        {
          name: translate('nonBoubyanCustomer'),
          key: 'notClientPercentage',
          sortable: true,
        },
        {
          name: translate('repaymentPeriod'),
          key: 'repaymentPeriod',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusBank = async (bank) => {
        const deletedMessage = bank.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_BANK_RATE, bank.id);
          await store.dispatch(Actions.GET_BANK_RATES);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = bank.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const editBank = (id) => {
        router.push({ name: 'bank-rates-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_BANK_RATE_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_BANK_RATES);
        loading.value = false;
      };
      const fireError = () => {
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        Swal.fire({
          text: translate(error[0]),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: translate('tryAgainExcl'),
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger',
          },
        });
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };
      const uploadBankRateFile = async (e) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const bankRateFile = e.target.files[0];
        let formData = new FormData();
        formData.append('excel', bankRateFile);
        const isUpload = await store.dispatch(
          Actions.IMPORT_BANK_RATE,
          formData
        );
        if (isUpload) {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPLOAD_BANK_RATES'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            await store.dispatch(Actions.GET_BANK_RATES);
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            e.target.value = '';
          });
        } else {
          fireError();
          e.target.value = '';
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.bankRatesList),
        tableHeader,
        toggleStatusBank,
        translate,
        totalItems: computed(() => store.getters.bankRatesListCount),
        pageChanged,
        goTo,
        editBank,
        loading,
        can,
        uploadBankRateFile,
        submitButton,
      };
    },
  });
